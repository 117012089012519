// Import our CSS
import styles from "../css/app.pcss";
import "swiper/swiper-bundle.css";

// App main
const main = async () => {
	await import("lazysizes/plugins/bgset/ls.bgset");
};

// Execute async function
main().then(() => {
	// Helpers
	var parseHTML = function (str) {
		const tmp = document.implementation.createHTMLDocument("tmp");
		tmp.body.innerHTML = str;
		return tmp.body.children;
	};

	var _si = function (selector, context = document, returnNull = false) {
		const tmp = context.querySelector(selector);
		return tmp ? tmp : returnNull ? null : _c("div");
	};
	var _s = function (selector, context = document) {
		return Array.apply(null, context.querySelectorAll(selector));
	};

	var _c = function (elm) {
		return document.createElement(elm);
	};

	var _ael = function (sel, ev, callback) {
		document.addEventListener(ev, (e) => {
			if (e.target === document) {
				return;
			}
			const that = e.target.closest(sel);
			if (that) {
				callback(that, e);
			}
		});
	};

	// END HELPERS
	const html = _si("html");
	const body = document.body;
	const navUl = _s("nav > ul > li");
	const homeUrl = window.location.href;
	let navUlhalf = navUl.length / 2 - 1;
	let halfUl = navUl[navUlhalf];
	let logoElm = _c("li");
	let setUrl = "/";
	if (homeUrl.includes("/en/")) {
		setUrl = "/en/";
	} else {
		setUrl = "/";
	}
	halfUl.insertAdjacentElement("afterend", logoElm);
	logoElm.innerHTML =
		'<a href="' +
		setUrl +
		'" class="css--main-logo hidden md:block"><svg class="main-logo h-12 w-20"><use xlink:href="#main-logo-dark"></use></svg></a>';
	let navOpen = false;
	window.addEventListener("load", function () {
		body.style.opacity = "1";
		body.classList.add("loaded");
	});
	_ael("#js--toggle-menu", "click", (elm, e) => {
		e.preventDefault();
		if (!navOpen) {
			navOpen = true;
			body.classList.add("nav-open");
		} else {
			navOpen = false;
			body.classList.remove("nav-open");
		}
	});

	// Swipers

	const swiperSlideshow = new Swiper(".swiper-slideshow", {
		speed: 400,
		spaceBetween: 30,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
	const thumbsSwiper = new Swiper(".swiper-thumb", {
		speed: 400,
		spaceBetween: 10,
		slidesPerView: 4,
		loop: true,
	});
	const swiper = new Swiper(".swiper-main", {
		speed: 400,
		spaceBetween: 100,
		thumbs: {
			swiper: thumbsSwiper,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
